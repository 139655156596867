// Global styles
@import "../../node_modules/foundation-sites/scss/foundation";

@include foundation-everything;

@import "settings";
@import "variables";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-buttons {
  display: inline-block;
  white-space: nowrap;

}

.ops-alert-element {
  margin-top: 16px;
  text-align: center;
}

.switches-radio {
  display: flex;
  flex-direction: row;
}

.radio {
  margin: 0 8px;
}

.super-small {
  margin: 5px;
  border: 1px;
  border-style: solid;
  border-color: black;
  padding: .2rem;
  font-size: .8rem;
}

.header {
  height: 80px;
  background-color: #8a8a8a;
  color: white;
  padding: 16px;
  font-size: 16px;
  font-family: sans-serif;
  max-width: 100%;
}

.full-row {
  max-width: 100%;
}

.empty-column {
  min-height: 1px;
}

.spins-logo {
  margin-right: 32px;
  height: 48px;
  width: 80px;
}

.ingestion-summary-data {
  font-size: 10px;
}

.page-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.page-name {
  font-size: 24px;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
}

.nav-title-bar {
  margin-top: 16px;
  width: 90%;
}

.nav {
  padding: 0 8px;
  width: 90%;
  display: flex;

  .i {
    float: left;
    cursor: pointer;
    color: #FFF;
    display: flex;
    text-decoration: none;
    font-size: 14px;
    margin-left: 80px;
    padding: 15px;
  }

  .link {
    color: #FFFFFF !important;
  }

  .link:hover {
    color: #000000 !important;
  }
}

.top-nav-menu-end {
  display: flex;
  align-content: end;
}

.spinner-container {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;

  .spinner {
    margin-right: 8px;
  }
}

.update-button {
  margin-top: 16px;
  vertical-align: top;
  align-self: flex-end;
  margin-left: 5px;
  margin-right: 5px;
}

.modal-button {
  margin: 8px
}

.services-button {
  margin-left: 8px;
}

.id-input {
  width: 400px;
  display: inline-flex;
  font-size: 14px;
  font-weight: unset;
}

.input {
  width: 150px;
  height: 40px;
  display: inline-block;
  margin-top: 16px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
}

.dashboard-page {
  margin: 16px 36px;

  button {
    margin-right: 8px
  }

  .deprecation-message {
    background-color: #8a8a8a;
    text-align: center;
    border-color: #0a0a0a;
    border-style: groove;
    padding: 24px;
    font-style: oblique;
    font-weight: bolder;
  }
}

.modal-text-area {
  height: 425px;
  resize: vertical;
}

.input-new-period {
  display: flex;
  width: 90%;
  align-content: flex-end;
}

.period-text-area {
  height: 41px;
  width: 25%;
  align-self: flex-end;
  margin-left: 40%;
}

.create-period-button, .upload-to-db-button, .download-csv-button, .json-compare, .upload-csv-button {
  vertical-align: top;
  align-self: flex-end;
  margin-left: 5px;
}

.task {
  display: block;
  border-bottom: 1px solid white;
}

body .ReactTable .rt-th {
  white-space: unset;
}

body .ReactTable .rt-td {
  white-space: unset;
}

body .ReactTable {
  min-height: 400px;
}

.dropzone-wrapper {
  width: 150px;
  display: inline-block;
}

.badge.alert {
  margin-left: 5px;
  padding: 0.5em;
}

.select-period {
  align-items: center;

  display: inline-flex;
  font-size: 14px;
  font-weight: normal;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.copy-period-group {
  width: 250px;
  display: inline-flex;
}

button {
  cursor: pointer;
}